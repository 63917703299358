import React, {useEffect, useState, useCallback, useRef} from "react";
import useTraining from "./hooks/index";
import {Alert, AlertColor, Grid, LinearProgress, Snackbar} from "@mui/material";
import {FRAMES_LENGTH_FOR_PREDICT, FRAMES_LENGTH_FOR_TRAINING} from "../../../constants";
import '../../App.css';
import ToggleSwitch from "../utils/toggleSwitch";
import {adjustCanvasVideoSize} from "../utils/resizeHandlers";
import {toggleAlphabetDetection} from '../effects-utils/alphabetHandlers';
import {handleThumbsUpTraining, handleThumbsDownTraining} from '../effects-utils/thumbsHandlers';
import {useResetWordOnAlphabetDetection} from '../effects-utils/alphabetEffects';
import {useUpdateProgress} from '../effects-utils/progressEffects';
import TermsAndConditionsDialog from "../utils/TermsAndConditionsDialog";
import {Select, MenuItem, FormControl, InputLabel} from "@mui/material";
import {getAvailableWords} from "../api/api";
import ConfirmationTrainingDialog from "../utils/confirmationTrainingDialog";
import {CountdownCircleTimer} from "react-countdown-circle-timer";


type AlertType = { word: string | null; severity: string | null; probability: number; alphabet: boolean };

function TrainingCapture() {

    // State for terms and conditions modal and user details
    const [termsModalOpen, setTermsModalOpen] = useState(!localStorage.getItem('termsAccepted'));
    const [isOver18, setIsOver18] = useState(false);
    const [fullName, setFullName] = useState('');
    const [userEmail, setEmail] = useState('');
    const [selectedLetter, setSelectedLetter] = useState<string | null>(null);
    const [selectedWord, setSelectedWord] = useState<string | null>(null);
    const [selectedGroup, setSelectedGroup] = useState(0)

    const [pendingResults, setPendingResults] = useState(null);

    const selectedWordRef = useRef(selectedWord);

    useEffect(() => {
        selectedWordRef.current = selectedWord;
    }, [selectedWord]);

    const selectedLetterRef = useRef(selectedLetter);

    useEffect(() => {
        selectedLetterRef.current = selectedLetter;
    }, [selectedLetter]);


    const handleCloseTermsModal = () => {
        if (isOver18 && fullName && userEmail) {
            localStorage.setItem('termsAccepted', 'true');
            localStorage.setItem('FullName', fullName);
            localStorage.setItem('userEmail', userEmail);
            setTermsModalOpen(false);
            location.reload()
        } else {
            alert("Por favor, complete todos los campos y confirme que es mayor de 18 años para continuar.");
        }
    };

    const [isModalOpen, setModalOpen] = useState(false);
    const isModalOpenRef = useRef(false);
    const [alphabetDetection, setAlphabetDetection] = useState(false);
    const currentFramesLengthForPredict = useRef(FRAMES_LENGTH_FOR_TRAINING);
    const [currentWord, setCurrentWord] = useState('');

    const [availableWords, setAvailableWords] = useState<any>(null);
    const alphabetDetectionRef = useRef(alphabetDetection);

    // countdown
    const [isPlaying, setIsPlaying] = useState(false);
    const isPlayingRef = useRef(isPlaying);

    useEffect(() => {
        console.log("isPlaying state updated:", isPlaying);
        isPlayingRef.current = isPlaying;
    }, [isPlaying]);

    const [showCountdown, setShowCountdown] = useState(false);

    useEffect(() => {
        async function fetchWords() {
            const fetchedWords: unknown = await getAvailableWords();
            setAvailableWords(fetchedWords);
        }

        fetchWords();
    }, []);

    useEffect(() => {
        alphabetDetectionRef.current = alphabetDetection;
    }, [alphabetDetection]);


    const [snackbarOpen, setSnackbarOpen] = useState({open: false, message: '', severity: 'success'});

    const handleCloseSnackbar = () => {
        setSnackbarOpen({...snackbarOpen, open: false});
    };

    const {
        videoElement,
        maxVideoWidth,
        maxVideoHeight,
        canvasEl,
        getProgress,
        resetProgress,
    } = useTraining({
        isDialogOpen: isModalOpenRef,
        alphabetDetection,
        selectedLetterRef,
        selectedWordRef,
        setPendingResults,
        setSnackbar: setSnackbarOpen,
        setModalOpen,
        isPlayingRef
    });

    const [alerts, setAlerts] = useState<AlertType[]>([]);
    useResetWordOnAlphabetDetection(alphabetDetection, setCurrentWord);

    if (termsModalOpen) {
        return (
            <TermsAndConditionsDialog
                open={termsModalOpen}
                onClose={handleCloseTermsModal}
                isOver18={isOver18}
                setIsOver18={setIsOver18}
                fullName={fullName}
                setFullName={setFullName}
                userEmail={userEmail}
                setEmail={setEmail}
                handleCloseTermsModal={handleCloseTermsModal}
            />
        );
    }

    let wordStore = alphabetDetection ? selectedLetterRef.current : selectedWordRef.current

    const renderTime = ({remainingTime}) => {
        if (remainingTime === 0) {
            return <div className="timer">Too lale...</div>;
        }

        return (
            <div className="timer">
                <div className="value" style={{fontSize: '5em'}}>{remainingTime}</div>
            </div>
        );
    };

    return (
        <div className="container">
            <video className="video" playsInline ref={videoElement}/>
            <canvas className="canvas" ref={canvasEl} width={maxVideoWidth} height={maxVideoHeight}/>
            <div className="container" style={showCountdown ? {
                zIndex: 5,
                background: 'linear-gradient(180deg, rgba(26, 2, 90, 0.85) 2.74%, rgba(103, 80, 164, 0.60) 75.49%, rgba(103, 80, 164, 0.09) 104.88%)'
            } : {}}>
                <Grid
                    container
                    spacing={0}
                    direction="column"
                    alignItems="center"
                    justifyContent="center"
                    sx={{minHeight: '100vh'}}
                >
                    <Grid item xs={3}>
                        {showCountdown && (
                            <CountdownCircleTimer
                                isPlaying={isPlaying}
                                duration={2}
                                colors={["#7371EF", "#7573CF"]}
                                colorsTime={[2, 1]}
                                onComplete={() => {
                                    setShowCountdown(false);
                                    setIsPlaying(false);
                                    return {shouldRepeat: false, delay: 1};
                                }}
                            >
                                {renderTime}
                            </CountdownCircleTimer>
                        )}
                    </Grid>
                </Grid>
            </div>
            <ConfirmationTrainingDialog
                isOpen={isModalOpen}
                onClose={() => setModalOpen(false)}
                word={wordStore}
                onThumbsUp={() => handleThumbsUpTraining(pendingResults, setSnackbarOpen, setPendingResults, setModalOpen, isModalOpenRef, setIsPlaying, isPlayingRef, setShowCountdown)}
                onThumbsDown={() => handleThumbsDownTraining(setModalOpen, isModalOpenRef, setIsPlaying, isPlayingRef, setShowCountdown)}
            />
            {/*<LinearProgress*/}
            {/*    variant="determinate"*/}
            {/*    value={(progress / currentFramesLengthForPredict.current) * 100}*/}
            {/*    style={{ position: 'absolute', bottom: 0, width: '100%', zIndex: 3, height: '0.6em' }}*/}
            {/*/>*/}
            <div className={'control-toggle-container'}>
                <Snackbar open={snackbarOpen.open} onClose={handleCloseSnackbar}
                          anchorOrigin={{vertical: 'top', horizontal: 'right'}}>
                    <Alert onClose={handleCloseSnackbar} severity={snackbarOpen.severity as AlertColor}>
                        {snackbarOpen.message}
                    </Alert>
                </Snackbar>
                <ToggleSwitch label="Alfabeto" checked={alphabetDetection}
                              onChange={() => setAlphabetDetection(toggleAlphabetDetection(alphabetDetection, setAlerts, setCurrentWord, currentFramesLengthForPredict))}/>
                {!alphabetDetection && availableWords && (
                    <div style={{marginBottom: '1em'}}>
                        <FormControl variant="outlined" style={{minWidth: 120, marginLeft: 10}}>
                            <InputLabel htmlFor="group-select">Grupo</InputLabel>
                            <Select
                                value={selectedGroup}
                                onChange={event => {
                                    setSelectedGroup(+event.target.value);
                                    setSelectedWord('');
                                    resetProgress()
                                }}
                                label="Grupo"
                                inputProps={{
                                    name: 'group',
                                    id: 'group-select',
                                }}
                            >
                                {Array.from({ length: 16 }, (_, index) => (
                                    <MenuItem key={index} value={index}>
                                        {`Grupo ${index}`}
                                    </MenuItem>
                                ))}

                            </Select>
                        </FormControl>
                    </div>
                )}
                {!alphabetDetection && availableWords && (
                    <div>
                        <FormControl variant="outlined" style={{minWidth: 120, marginLeft: 10}}>
                            <InputLabel htmlFor="word-select">Palabra</InputLabel>
                            <Select
                                value={selectedWord}
                                onChange={event => {
                                    setSelectedWord(event.target.value as string);
                                    setIsPlaying(true);
                                    setShowCountdown(true)
                                    resetProgress()
                                }}
                                label="Palabra"
                                inputProps={{
                                    name: 'word',
                                    id: 'word-select',
                                }}
                            >
                                {Object.values(availableWords[selectedGroup][0]).map((word, i) => (
                                    <MenuItem key={i} value={word as any}>
                                        {word as any }
                                    </MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                    </div>
                )}
                {alphabetDetection && (
                    <FormControl variant="outlined" style={{minWidth: 120, marginLeft: 10}}>
                        <InputLabel htmlFor="alphabet-select">Letra</InputLabel>
                        <Select
                            value={selectedLetter}
                            onChange={event => {
                                setSelectedLetter(event.target.value as string)
                                setIsPlaying(true);
                                setShowCountdown(true)
                                resetProgress()
                            }
                            }
                            label="Letra"
                            inputProps={{
                                name: 'alphabet',
                                id: 'alphabet-select',
                            }}
                        >
                            {[...Array(26)].map((_, i) => (
                                <MenuItem key={i} value={String.fromCharCode(65 + i)}>
                                    {String.fromCharCode(65 + i)}
                                </MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                )}
            </div>
        </div>
    );
}

export default TrainingCapture;